import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`JavaScript는 웹의 초창기였던 1995년에 Netscape Navigator라는 웹 브라우저에 처음으로 탑재되어 세상에 공개됐습니다. JavaScript는 Java와 많은 부분에서 다르지만, 마케팅 상의 이유로 그 문법과 이름이 Java와 유사하게 만들어진 덕분에 아직도 Java와 JavaScript를 혼동하는 사람들이 많습니다. `}<strong parentName="p">{`"Java와 JavaScript 사이의 관계는 햄과 햄스터 사이의 관계와 같다"`}</strong>{`와 같이 그 미묘한 관계를 풍자하는 많은 `}<a parentName="p" {...{
        "href": "http://javascriptisnotjava.io/"
      }}>{`농담`}</a>{`들이 있습니다.`}</p>
    <h2>{`언어와 구동 환경`}</h2>
    <p>{`JavaScript 언어 자체에는 다른 범용 프로그래밍 언어(general-purpose programming language)에 비해 적은 양의 기능(주로 `}<strong parentName="p">{`코드의 실행`}</strong>{`과 관련된 것)을 포함하고 있습니다. 하지만 이 JavaScript를 구동할 수 있는 `}<strong parentName="p">{`구동 환경`}</strong>{`에 여러 기능(주로 `}<strong parentName="p">{`입출력`}</strong>{`과 관련된 것)이 포함되어 있어서, 우리는 이 기능을 이용해 쓸모있는 응용 프로그램을 만들 수 있게 됩니다. JavaScript 구동 환경에는 웹 브라우저, 웹 서버 (Node.js), 게임 엔진, 포토샵(!) 등 많은 프로그램들이 있습니다.`}</p>
    <h2>{`ECMAScript와 브라우저 잔혹사`}</h2>
    <p>{`몇몇 유명한 프로그래밍 언어와 마찬가지로, JavaScript라는 언어에는 표준 명세(standard specification)라는 것이 존재합니다. 여러 브라우저 개발사에서 통일된 JavaScript 기능을 구현할 수 있도록, 언어의 문법과 기능을 세부적으로 정의해놓은 설계도라고 생각하면 됩니다. JavaScript의 표준 명세는 `}<strong parentName="p">{`ECMAScript`}</strong>{`라는 이름을 갖고 있는데, Netscape에 탑재되었던 JavaScript 구현체(implementation)를 ECMA(European Computer Manufacturer’s Association)라는 단체에서 표준화한 것입니다. 이 표준은 1997년에 처음 제정되어 계속 발전하고 있는 중입니다.`}</p>
    <h3>{`브라우저 간 호환성`}</h3>
    <p>{`1999년에 ECMAScript 3판(줄여서 ES3)이 공개될 때까지만 해도 여러 웹 브라우저들의 독자 표준이 난립하는 상황이었습니다. 예를 들어, Internet Explorer에서는 잘 동작하는 기능이 Firefox에서는 동작하지 않는다거나, 그 반대와 같은 경우가 많았습니다. 그래서 이 시절에는 어느 브라우저를 사용하든 잘 동작하는 JavaScript 코드를 짜는 것, 즉 `}<strong parentName="p">{`브라우저 간 호환성(cross-browser compatibility)`}</strong>{`를 확보하는 것이 웹 개발자들에게 큰 고민거리였습니다. (물론 "이 웹 사이트는 IE에서 잘 동작합니다"라는 문구를 웹 사이트에 집어넣고 다른 브라우저를 무시하는 경우도 많았습니다...)`}</p>
    <p>{`이런 호환성 문제를 해소하기 위해, 한 편으로는 Dojo, Prototype, jQuery와 같이 개발자 대신 브라우저 간 호환성 문제를 해결해주는 `}<strong parentName="p">{`라이브러리`}</strong>{`들이 개발되었고, 또 한 편으로는 각 브라우저 개발사들이 `}<strong parentName="p">{`ECMAScript 표준`}</strong>{`을 따라 브라우저를 구현하기 시작했습니다.`}</p>
    <p>{`ES5가 공개된 2009년 경부터는 브라우저 간 호환성 문제가 조금씩 해결되기 시작했고, 그 전까지는 천덕꾸러기 취급을 받던 JavaScript에 여러 가지 기능이 추가되어 쓸만한 범용 프로그래밍 언어로서의 면모를 갖추기 시작했습니다.`}</p>
    <h3>{`ES2015, 그 이후`}</h3>
    <p>{`ES5의 다음 버전부터는 해당 버전이 공개된 연도를 버전 번호로 사용하고 있습니다. 즉, ES5의 다음 버전의 이름은 ES6가 아니라 `}<strong parentName="p">{`ES2015`}</strong>{` 입니다. 다만 ES2015라는 이름이 확정되기 전까지는 ES5의 다음 버전이라는 의미에서 ES6라는 이름이 널리 사용되었고, 아직까지도 ES6라는 이름이 사용되는 경우가 있습니다. 하지만 정식 명칭은 ES2015라는 사실을 기억하세요.`}</p>
    <p>{`ES2015에서 엄청나게 많은 문법과 기능(클래스, 모듈, 분해대입, 템플릿 문자열, 블록 스코프, 반복자, 프록시 등등...)이 추가되고, Node.js 등 웹 브라우저 외에도 JavaScript를 구동할 수 있는 구동 환경의 종류가 많아지면서, 이제 JavaScript는 Python 등 다른 범용 프로그래밍 언어와 비교해도 전혀 뒤쳐지지 않는 범용 프로그래밍 언어가 되었습니다. ES2015부터는 매년 새로운 버전의 ECMAScript가 공개되고 있습니다. 최신 명세는 `}<a parentName="p" {...{
        "href": "https://tc39.github.io/ecma262/"
      }}>{`이 곳`}</a>{`에서, 브라우저 별 기능 개발 현황은 `}<a parentName="p" {...{
        "href": "http://kangax.github.io/compat-table/es2016plus/"
      }}>{`이 곳`}</a>{`에서 확인해볼 수 있습니다.`}</p>
    <h2>{`빠르게 발전하는 언어, 따라가는 개발자`}</h2>
    <p>{`이렇게 나날이 발전하는 JavaScript에 새롭게 추가된 우아한 기능을 이용해서 개발을 할 수 있다면 행복할 테지만, 한 가지 문제가 있습니다. 최신 버전의 JavaScript를 지원하지 않는 브라우저가 `}<strong parentName="p">{`항상 존재`}</strong>{`한다는 점입니다. 구형 브라우저를 업데이트하지 않고 사용하는 사용자는 굉장히 많습니다. 최신 버전의 브라우저를 사용한다고 하더라도, 각 브라우저마다 업데이트 주기가 다르므로 브라우저마다 지원하는 JavaScript 기능이 조금씩 다릅니다.`}</p>
    <p>{`이런 문제를 해결하기 위해, 요즘은 두 가지 도구를 사용하는데 바로 `}<strong parentName="p">{`트랜스파일러(transpiler)`}</strong>{`와 `}<strong parentName="p">{`폴리필(polyfill)`}</strong>{`입니다. 근래에는 트랜스파일러와 폴리필이 없는 웹 개발을 상상하기 어려울 정도로, 이런 도구들이 널리 활용되고 있습니다.`}</p>
    <h3>{`트랜스파일러 (Transpiler)`}</h3>
    <p>{`트랜스파일러는 최신 버전 JavaScript의 `}<strong parentName="p">{`문법`}</strong>{`을 `}<strong parentName="p">{`똑같이 동작하는 이전 버전 JavaScript의 문법`}</strong>{`으로 바꾸어주는 도구입니다. 마법같은 도구죠! 이를 이용해 개발자는 최신 버전으로 코딩을 하고, 실제로 사용자에게 배포할 코드는 구형 브라우저에서도 잘 동작하도록 변환해줄 수 있습니다. 요즈음 많이 사용되는 트랜스파일러에는 `}<a parentName="p" {...{
        "href": "https://babeljs.io/"
      }}>{`Babel`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{` 같은 것들이 있습니다.`}</p>
    <h3>{`폴리필 (Polyfill)`}</h3>
    <p>{`JavaScript를 실행하는 구동 환경에는 여러 가지 `}<strong parentName="p">{`문법과 기능`}</strong>{`이 추가됩니다. 이를 구형 환경에서도 사용할 수 있도록 똑같이 구현해놓은 라이브러리를 폴리필(polyfill) 혹은 심(shim)이라고 부릅니다. 본인이 개발하는 프로그램이 어느 정도까지의 구형 환경을 지원해야 하는지를 결정한 후, 적절한 폴리필을 프로젝트에 추가하세요. 예를 들어, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ko/docs/Web/API/Fetch_API"
      }}>{`Fetch API`}</a>{`를 활용한 웹 사이트를 Internet Explorer 10에서도 문제없이 동작할 수 있도록 만드려면 `}<a parentName="p" {...{
        "href": "https://github.com/github/fetch"
      }}>{`해당 기능에 대한 폴리필`}</a>{`을 프로젝트에 추가시키세요.`}</p>
    <h3>{`이용중인 환경의 JavaScript 기능 지원 여부 확인하기`}</h3>
    <p>{`다만 최신 버전에 포함된 문법과 기능을 전부 이전 버전에서 구현할 수 있는 것은 아니고, 또 트랜스파일러마다 지원하는 기능의 종류가 달라서, 항상 모든 최신 기능을 사용할 수 있는 것은 아닙니다. 최신 기능을 사용하고 싶다면, 본인이 사용중인 트랜스파일러가 해당 기능을 지원하도록 설정되어 있는지, 프로젝트에 적절한 폴리필이 포함되어 있는지 확인하세요. JavaScript 최신 버전에 관심이 있다면, 페이스북이나 트위터 등의 매체를 통해 JavaScript 최신 버전에 대한 뉴스를 수집하고,
`}<a parentName="p" {...{
        "href": "http://kangax.github.io/compat-table/es6/"
      }}>{`브라우저열 지원 기능 일람`}</a>{`을 제공하는 웹사이트를 주기적으로 확인해보세요.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      